$assetPath: '../../assets/images/common';

#bg {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: var(--ion-drawer-background);
  .form-container{ position: absolute;transform: translate(-50%, -50%);top: 50%;left: 50%;
  .logo{width: 190px;margin: 0 auto 20px;}
  }
  .card {
    color: var(--text-white) !important;
    background: var(--ion-background);
   
    
    background-size: cover;
    display: flex;
    flex-direction: column;
    width: 485px;
    padding: 40px;
    .logo{width: 190px;margin: 20px auto 0;}
    .input-label {
      color: #000;
    }
    .MuiInputBase-root .MuiSvgIcon-root {
     // color: var(--text-white) !important;
    }
    input:-webkit-autofill {
      -webkit-text-fill-color: #000 !important;
      -webkit-transition: background-color 9999s ease-out !important;
    }
    .logo {
      justify-content: center;
      height: 50px;
      margin-top: 12px;
      // filter: var(--game-category-icons-login);
    }

    .MuiInputBase-root {
      border: 1px solid var(--text-white) !important;
      background: #eaeef3 !important;
      border-radius: 8px;
      height: 50px;
      .MuiInputBase-input {
        color: #000 !important;
        border: none !important;
        background: #eaeef3 !important;
        border-radius: 10px;
      }
    }

    .MuiButton-contained {
      background-color: var(--ion-color-primary) !important;
    }
    .MuiButton-label {
      color: var(--table-head-text) !important;

      .MuiButton-endIcon {
        color: var(--table-head-text) !important;
      }
    }
    &.MuiPaper-rounded{border-radius: 18px;}
    .title{color: var(--ion-color-warning-contrast);text-align: center;font-weight: 600;font-size: 24px;
      @media only screen and (max-width:400px){  
        font-size: 18px !important;
     
      }
    }
    .sub-title{font-size: 18px;color: var(--ion-color-warning-contrast);text-align: center;
      @media only screen and (max-width:400px){  
        font-size: 16px !important;
     
      }
    }
    .login-form-ctn{padding: 0;
    .user-name, .pwd-field{max-width: 100%;}
    .PrivateNotchedOutline-root-1{border: none;}
    .login-form-btn{max-width: 100%;border-radius: 8px;}
    }
  }
}
@media only screen and (width: 768px) and (height: 1024px) and (orientation: portrait) {
  #bg {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    .card {
      border-radius: 10px;
    
      background: var(--ion-drawer-background);
      background-size: cover;
      display: flex;
      flex-direction: column;
      .logo {
        justify-content: center;
        height: 38px;
        margin-top: 12px;
      }
      .user-name, .pwd-field{max-width: 100% !important;width: 100% !important;}
    }
  }
}

@media (max-width: 600px) {
  #bg {
    background-position: center center;
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
   
    .card {

      border-radius: 0px;
      background-size: cover;
      display: flex;
      flex-direction: column;
      width: 100%;
      padding: 20px 20px 40px;
      .logo {
        justify-content: center;
        height: 35px;
        margin-top: 12px;
      }
      .user-name, .pwd-field{max-width: 100% !important;width: 100% !important;padding: 0;}
      .usr-input, .pwd-input{display: block;}
      .login-form-ctn{height: auto;width:100%;
        .login-form-btn{width: 100% !important;}
       
      }
      .title{font-size: 24px;}
    }
    .form-container{position: inherit;transform: inherit;left: auto;top: auto;padding: 20px;width: 100%;}
  }

}



   
