.login-form-ctn {
  .bottom-link {
    margin-top: 24px;
    margin-bottom: 6px;
    font-size: 16px;
    color: var(--text-color);
    text-align: center;

    .register-link {
      color: var(--ion-color-primary);
      cursor: pointer;
    }
  }
  .login-form-restrictions-info {
    width: 100%;
    display: inline-flex;
    justify-content: center;
    margin-bottom: 18px;
    margin-top: 12px;

    .restrictions-info-ctn {
      display: inline-flex;
      align-items: center;
      .restrictions-info-btn {
        .btn-text {
          display: none;
          color: var(--ion-color-text-contrast);
        }

        .age-text {
          font-size: 16px;
          line-height: 20px;
          padding: 8px 3px;
          color: var(--color-text-btn);
          border: 1px solid var(--ion-text-color-contrast);
          ion-icon {
            color: var(--text-color-contrast);
          }
        }

        .MuiSvgIcon-root {
          font-size: 40px;
          color: var(--color-text-btn);
        }
      }
    }
  }

  .MuiButton-label {
    color: var(--ion-color-text-contrast);
  }
}

.scanner-ctn {
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 8%;

  .scanner {
    width: 50% !important;
    margin-left: 22% !important;
  }
  .usr-input {
    display: inline-grid;
  }

  .MuiInputBase-input {
    font-size: 16px;
  }

  .MuiIconButton-root {
    padding: 0;
    opacity: 0.4;
  }

  .usr-input {
    margin-top: 30px;
    width: 100%;
  }

  .login-err-msg {
    color: red;
    margin-top: 20px;
    text-align: center;
  }

  input[type='number']::-webkit-outer-spin-button,
  input[type='number']::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}

@media (min-width: 350px) and (max-width: 420px) {
  .scanner-ctn {
    .login-form-btn {
      width: 100% !important;
      padding-left: 1%;
      padding-right: 1%;
    }
  }
}

@media (min-width: 220px) and (max-width: 349px) {

  .scanner-ctn {
    .login-form-btn {
      width: 100% !important;
      padding-left: 3%;
      padding-right: 1%;
    }
  }
}

@media (max-width: 600px) {
  .scanner-ctn {
    height: fit-content;
    display: inline-block;
    flex-direction: column;
    justify-content: center !important;
    align-items: center;
    padding: 5%;
    width: 100%;

    .scanner {
      width: 50%;
      margin-left: 22%;
    }
  }
}
